import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = () => {
    loginWithRedirect({
      redirectUri: `${window.location.origin}/dashboard`
    });
  };

  return (
    <Button variant="contained" color="primary" onClick={handleLogin}>
      Log In
    </Button>
  );
};

export default LoginButton;

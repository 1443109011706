import React, { useEffect, useRef, useState } from 'react';
import Chart from "./Chart";
import config from '../config';


function PX(props) {
    return <Chart {...props} baseUrl={config.charts.chartBaseURL} dashboardId={config.charts.pxDashboardId} componentName='PX' />
}

export default PX;

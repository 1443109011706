import React from 'react';
import Chart from "./Chart";
import config from '../config';


function Automations(props) {
    return <Chart {...props} baseUrl={config.charts.chartBaseURL} dashboardId={config.charts.automationsId} componentName='Automations' />
}

export default Automations;

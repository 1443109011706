import React, { useState } from 'react';
import { Select, MenuItem, ListSubheader, TextField, Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import TabNavigation from './TabNavigation';
import LogoutButton from './Logout';
import moment from "moment";
import Data from "./Data.js"
const DATE_FORMAT = "MM/DD/YYYY";

const defaultStartDate = moment().startOf("month").toDate();
const defaultEndDate = moment().endOf("day").toDate();


const organizations = Data;

const Dashboard = () => {
    const [selectedOrg, setSelectedOrg] = useState(null);
    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);

    const handleOrgChange = (event) => {
        setSelectedOrg(event.target.value);
    };

    const orgTypes = [...new Set(organizations.map((org) => org.organizationType))];

    return (
        <Grid container spacing={2} direction="column">
            <Grid item>
                <Select
                    variant="outlined"
                    value={selectedOrg}
                    onChange={handleOrgChange}
                    style={{ width: '100%' }}
                >
                    <MenuItem value={null}>All Data</MenuItem>
                    {orgTypes.map((type) => [
                        <ListSubheader key={type}>{type}</ListSubheader>,
                        organizations
                            .filter((org) => org.organizationType === type)
                            .map((org) => (
                                <MenuItem key={org._id} value={org}>{org.name}</MenuItem>
                            ))
                    ])}
                </Select>
            </Grid>
            <Grid item>
                <DatePicker
                    label="Start Date"
                    value={startDate}
                    onChange={(newDate) => setStartDate(newDate)}
                    renderInput={(params) => <TextField {...params} />}
                />
                <DatePicker
                    label="End Date"
                    value={endDate}
                    onChange={(newDate) => setEndDate(newDate)}
                    renderInput={(params) => <TextField {...params} />}
                />
            </Grid>
            <Grid item>
                <TabNavigation selectedOrg={selectedOrg} startDate={moment(startDate).format(DATE_FORMAT)} endDate={moment(endDate).format(DATE_FORMAT)} />
            </Grid>
        </Grid>
    );
};

export default Dashboard;

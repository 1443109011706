import React from 'react';
import { Tabs, Tab } from '@mui/material';
import GCN from './GCN';
import MedicalGroup from './MedicalGroup';
import WebAnalytics from './WebAnalytics';
import PX from './PX';
import Automations from './Automations';
import UrgentCare from './UrgentCare';

const TabNavigation = ({ selectedOrg, startDate, endDate }) => {  // receive selectedOrg as a prop
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="GCN" />
        <Tab label="Medical Group" />
        <Tab label="Web Analytics" />
        <Tab label="PX" />
        <Tab label="Automations" />
        <Tab label="Urgent Care" />
      </Tabs>
      {value === 0 && <GCN selectedOrg={selectedOrg} startDate={startDate} endDate={endDate} />}
      {value === 1 && <MedicalGroup selectedOrg={selectedOrg} startDate={startDate} endDate={endDate} />}
      {value === 2 && <WebAnalytics selectedOrg={selectedOrg} startDate={startDate} endDate={endDate}  />}
      {value === 3 && <PX selectedOrg={selectedOrg} startDate={startDate} endDate={endDate}  />}
      {value === 4 && <Automations selectedOrg={selectedOrg} startDate={startDate} endDate={endDate} />}
      {value === 5 && <UrgentCare selectedOrg={selectedOrg} startDate={startDate} endDate={endDate} />}
    </div>
  );
};

export default TabNavigation;
import React from 'react';
import Chart from "./Chart";
import config from '../config';


function GCN(props) {
    return <Chart {...props} baseUrl={config.charts.chartBaseURL} dashboardId={config.charts.gcnDashboardId} componentName='GCN' />
}

export default GCN;

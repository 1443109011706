const config = {
    charts: {
        chartBaseURL: "https://charts.mongodb.com/charts-oh-production-olppe",
        gcnDashboardId: "64b58f44-2398-49eb-85e7-cb4fb579f372",
        medicalGroupDashboardId: "64b5611b-0662-46bd-8901-c1fa6e481ee3",
        webAnalyticsDashboardId: "64a6eaf1-c261-42d9-8220-e06977effcf5",
        pxDashboardId: "48321908-e2d9-4dd2-a98d-cb2ef82dd255",
        urgentCareId: "64b5d0b0-0662-47b1-8156-c1fa6eafe797",
        automationsId: "64b5d70d-be36-4c05-8756-a9103307b1de",
        gcnDashboard: {
            total: "64b58f44-2398-4eec-830f-cb4fb579f374",
            completed: "64b58f44-2398-4c44-8ca0-cb4fb579f386",
            newPatients: "64b58f44-2398-4de3-8732-cb4fb579f376",
            commericalPayor: "64b58f44-2398-45c7-8aa1-cb4fb579f388",
            totalChart: "64b58f44-2398-4b6f-84ae-cb4fb579f37c",
            topPerformers: "64b58f44-2398-4858-804e-cb4fb579f384",
            payorMix: "64b58f44-2398-468a-8409-cb4fb579f378",
            byReason: "64b58f44-2398-4a2b-8183-cb4fb579f37e",
            byAge: "64b58f44-2398-4362-855f-cb4fb579f380"
        }
    }
}

export default config;
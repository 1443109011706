import React from 'react';
import Chart from "./Chart";
import config from '../config';


function UrgentCare(props) {
    return <Chart {...props} baseUrl={config.charts.chartBaseURL} dashboardId={config.charts.urgentCareId} componentName='UrgentCare' />
}

export default UrgentCare;

import React, { useEffect, useRef, useState } from 'react';
import ChartsEmbedSDK from '@mongodb-js/charts-embed-dom';
import moment from 'moment';
import Data, {primeER, primeMG} from "./Data.js"

const DATE_FORMAT = "MM/DD/YYYY";


const BaseChart = ({ selectedOrg, startDate, endDate, baseUrl, dashboardId, componentName }) => {
    const [isDashboardRendered, setIsDashboardRendered] = useState(false);

    useEffect(() => {
        const sdk = new ChartsEmbedSDK({
            baseUrl,
            showAttribution: false,
        });

        const dashboard = sdk.createDashboard({
            dashboardId,
            height: '1200px',
            width: '1200px',
            autoRefresh: true,
        });


        dashboard.render(document.getElementById('dashboard'))
            .then(async () => {
                setIsDashboardRendered(true);
                let start;
                let end;
                if (startDate) start = moment(startDate, DATE_FORMAT).startOf("d").toDate();
                if (endDate) end = moment(endDate, DATE_FORMAT).endOf("d").toDate();
                const filter = {    };
                if (componentName === 'WebAnalytics') {
                    if (selectedOrg?.domain) filter.domain = selectedOrg.domain;
                    if (startDate && endDate) filter.createdAtDate = { $gte: start, $lte: end };
                } else {
                    if (selectedOrg?._id) filter.organizationId = selectedOrg._id;
                    else if (mapComponentToOrgType(componentName)) filter.organizationId = { $in: Data.filter((org) => org.organizationType === mapComponentToOrgType(componentName)).map((org) => org._id) };
                    if (startDate && endDate) {
                        filter.createdAt = { $gte: start, $lte: end };
                    }
                    if(selectedOrg?._id === "primeMG") filter.organizationId = { $in: primeMG };
                    if(selectedOrg?._id === "primeER") filter.organizationId = { $in: primeER };

                }
                console.log("filter", filter);
                await dashboard.setFilter(filter);
            })
            .catch((error) => {
                console.error('Dashboard failed to initialise', error);
            });
    }, [selectedOrg, startDate, endDate, baseUrl, dashboardId, componentName]);

    return (
        <div>
            <h2>{componentName}</h2>
            <p>Selected organization: {selectedOrg ? selectedOrg.name : 'No organization selected'}</p>
            {componentName === 'WebAnalytics' ? <p>Domain: {selectedOrg ? selectedOrg.domain : 'No domain selected'}</p> : null}
            <div id="dashboard"></div>
        </div>
    );
};

function mapComponentToOrgType(componentName) {
    switch (componentName) {
        case 'GCN':
            return 'emergencyRoom';
        case 'UrgentCare':
            return 'urgentCare';
        case 'MedicalGroup':
            return 'medicalGroup';
        default:
            return null;
    }
}


export default BaseChart;
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Dashboard from './components/Dashboard';
import LoginButton from './components/Login';
import LogoutButton from './components/Logout';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';

const AUTH0_DOMAIN = 'steerhealth-prod.us.auth0.com';
const AUTH0_CLIENT_ID = 'WQSvvEFoMkMkkKxIrTHl1JoHjPOueBFh';
const STEER_LOGO = "https://steerhealth.io/wp-content/uploads/2022/09/logo.png";

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#0d47a1',
    },
    secondary: {
      main: '#018786',
    },
  },
});

const providerConfig = {
  domain: AUTH0_DOMAIN,
  clientId: AUTH0_CLIENT_ID,
  authorizationParams: {
    redirect_uri: `${window.location.origin}/dashboard`,
  },
}

const ProtectedRoute = ({ children }) => {
  const { isLoading, error, isAuthenticated } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    alert(`Error, ${JSON.stringify(error)}`)
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

function App() {
  return (
    <Router>
      <Auth0Provider {...providerConfig}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Container maxWidth="xl">
            <Routes>
              <Route path="/login" element={
                <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
                    <Grid item>
                      <img src={STEER_LOGO} alt="logo" />
                    </Grid>
                    <Grid item>
                      <LoginButton />
                    </Grid>
                  </Grid>
                </div>
              } />
              <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
            </Routes>
          </Container>
        </ThemeProvider>
      </Auth0Provider>
    </Router>
  );
}

export default App;
